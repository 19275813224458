<template>
  <div class="px-4.5 mt-3">
    <p class="font-bold text-mdlh text-grey-dark-1">New Email Message</p>
    <div class="p-2 space-y-3">
      <div class="flex flex-grow space-x-2">
        <InputField
          label="Message Name"
          placeholder="e.g. Invitation Email"
          :defaultValue="nameType"
          :isTextOk="nameError"
          errorText="Message Name is required."
          :onChange="(value) => (nameType = value)"
          data-testid="name-field"
          class="w-1/2"
        />
        <InputField
          label="From Address"
          :defaultValue="message.from"
          :placeholder="message.from"
          class="w-1/2"
          :disabled="true"
          data-testid="address-field"
        />
      </div>
      <div class="flex space-x-2 items-center flex-grow">
        <InputField
          label="Subject"
          :onChange="(value) => (subject = value)"
          :defaultValue="subject"
          :isTextOk="subjectError"
          errorText="Subject is required."
          placeholder="e.g. Supply Chain Assessment 2021 etc."
          class="flex-grow"
          data-testid="subject-field"
        />
        <Select
          label="Audience"
          :options="audienceOptions"
          :value="selectedAudience"
          :preselectFirst="false"
          :onSelect="(value) => (selectedAudience = value)"
          class="flex-grow"
          data-testid="audience-select"
        />
      </div>
      <div class="flex space-x-2">
        <Select
          label="Timezone"
          v-model="timezone"
          :options="timezoneOptions"
          :preselectFirst="false"
          :onSelect="(value) => (timezone = value)"
          class="mr-2 w-28 max-w-1/2 flex-grow"
          disabled
        />
        <DatePicker
          v-model="message.sendDate"
          mode="dateTime"
          timezone="UTC"
          :key="message.sendDate + Math.random()"
          :is-required="true"
          :min-date="new Date()"
          data-testid="date-select"
          :model-config="{
            type: 'string',
            mask: 'YYYY-MM-DDTHH:mm:ss',
            timeAdjust: '06:00:00',
          }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div>
              <p class="text-sm-2 text-grey-light font-medium mb-0.4 relative">
                Send Date
              </p>
              <div class="flex -mr-2">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
                />
                <img
                  src="../../../assets/img/icons/date.svg"
                  class="relative right-4"
                />
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="absolute bottom-0 inset-x-0 h-68 opacity-60 bg-white rounded-b-lg" />
          </template>
        </DatePicker>
      </div>
      <div
        class="space-x-2 items-center flex"
        v-if="canRepeat"
      >
        <InputField
          label="Repeat Every"
          :defaultValue="message.nrDays"
          :propValue="message.nrDays"
          placeholder="0"
          class="w-1/2"
          type="number"
          :onChange="this.setNrDays"
          :disabled="this.mode == 'edit-launched' || this.mode == 'edit-started'"
          positiveOnly
        />
        <p class="text-grey-dark-1 text-md mt-2">
          days until assessment close date
        </p>
      </div>
      <div>
        <RTF
          label="Message"
          :content="html"
          :haveMinHeight="true"
          :error="messageError && 'Message is required.'"
          :updateHtml="(value) => (html = value)"
          data-testid="message-input"
        />
        <div class="flex justify-end space-x-2 mt-2">
          <Button
            text="Cancel"
            type="secondary"
            size="medium"
            @click.native="() => cancel(index)"
            data-testid="cancel-button"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            :disabled="!canSave"
            @click.native="() => save(message, index)"
            data-testid="save-button"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState} from 'vuex';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import InputField from '../../../components/InputField/InputField.vue';
import Select from '../../../components/Select/NewSelect.vue';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button/Button.vue';
import timezoneOptions from '../../../components/DateTimePicker/timezoneOptions';

Vue.component('date-picker', DatePicker);

const htmlValidator = (value) => value !== '<p></p>';

export default {
  components: { InputField, Select, DatePicker, Checkbox, Button },
  name: 'NewEmail',
  props: {
    index: { type: Number },
    newMessage: {
      type: Object,
    },
    save: {
      type: Function,
    },
    cancel: {
      type: Function,
    },
  },

  data: () => ({
    audienceOptions: [
      { label: 'All Participants', id: 0 },
      { label: 'Participants with Assessments in Progress', id: 1 },
      { label: 'Only Managers', id: 2 },
      { label: 'Only Employees', id: 3 },
    ],
    message: {},
    timezoneOptions,
  }),
  created() {
    this.message = this.newMessage;
  },
  validations: {
    nameType: { required },
    subject: { required },
    html: { htmlValidator },
  },
  methods: {
    setNrDays(value) {
        const computedValue = Math.max(0, Number.parseInt(value));
        this.message.nrDays = computedValue;
        this.message.messageFrequency = +(computedValue > 0);
    },
  },
  computed: {
    ...mapState({
      mode: (state) => state.assessmentSetup.mode,
    }),
    nameType: {
      get() {
        return this.message.messageName;
      },
      set(value) {
        this.$v.nameType.$touch();
        this.message.messageName = value;
      },
    },
    canRepeat() {
      return this.message.surveyMessageType !== 1;
  },
    timezone: {
      get() {
        if (!this.message.timezone) return { label: "(UTC) Coordinated Universal Time", id: "UTC" };
        return this.message.timezone;
      },
      set(value) {
        this.message.timezone = value;
      },
    },
    isRepeating: {
      get() {
        return this.message.messageFrequency === 1;
      },
      set(value) {
        this.message.messageFrequency = value ? 1 : 0;
      },
    },
    selectedAudience: {
      get() {
        return this.audienceOptions.filter(
          (item) => item.id === this.message.messageAudience
        )[0];
      },
      set(value) {
        this.message.messageAudience = value.id;
      },
    },
    subject: {
      get() {
        return this.message.subject;
      },
      set(value) {
        this.$v.subject.$touch();
        this.message.subject = value;
      },
    },
    html: {
      get() {
        return this.message.message;
      },
      set(value) {
        this.$v.html.$touch();
        this.message.message = value;
      },
    },
    canSave() {
      return !this.$v.$invalid;
    },
    subjectError() {
      if (this.$v.subject.$dirty) {
        if (!this.$v.subject.required) {
          return false;
        }
      }
      return null;
    },
    nameError() {
      if (this.$v.nameType.$dirty) {
        if (!this.$v.nameType.required) {
          return false;
        }
      }
      return null;
    },
    messageError() {
      if (this.$v.html.$dirty) {
        if (!this.$v.html.htmlValidator) {
          return true;
        }
      }
      return null;
    },
  },
};
</script>
